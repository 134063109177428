<template>
  <div class="upload-pushu">
    <div class="content-inner">
      <div class="pushu-header">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/web/user/my-pushu' }"
            >我的谱书</el-breadcrumb-item
          >
          <el-breadcrumb-item>{{ detail.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <el-form
        ref="upload-form"
        :inline="true"
        :model="detail"
        class="upload-form"
      >
        <el-form-item class="upload-form-item" label="编号">
          <!-- <el-input v-model="detail.sn"></el-input> -->
          <span>{{ detail.sn }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="题目">
          <span>{{ detail.title }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="价格">
          <span>{{ detail.price }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="描述">
          <span>{{ detail.description }}</span>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item class="upload-form-item" label="卷数">
          <span>{{ detail.juanshu }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="谱籍地">
          <span>{{ detail.pujidi }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="始祖">
          <span>{{ detail.shizu }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="始迁祖">
          <span>{{ detail.shiqianzu }}</span>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item class="upload-form-item" label="支系">
          <span>{{ detail.timing }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="修撰者">
          <span>{{ detail.xiuzhuanzhe }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="版本年">
          <span>{{ detail.banbennian }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="堂号">
          <span>{{ detail.tanghao }}</span>
        </el-form-item>
        <el-divider></el-divider>
        <el-form-item class="upload-form-item" label="字辈">
          <span>{{ detail.zibei }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="编撰日期">
          <span>{{ detail.zhuanxieriqi }}</span>
        </el-form-item>
        <el-form-item class="upload-form-item" label="发源地">
          <span>{{ detail.fayuandi }}</span>
        </el-form-item>
      </el-form>
    </div>
    <detail
      v-if="detail.id"
      :prop-detail="detail"
      style="position: absolute; width: calc(100% - 0px); top: calc(100% + 10px); left: 0; background: transparent"
    />
  </div>
</template>

<script>
import {
  Form,
  FormItem,
  Divider,
  Breadcrumb,
  BreadcrumbItem
} from "element-ui";
import { apiRoot } from "@/config";
import materialApis from "@/apis/material";
import Detail from "@/views/web/Detail";

const initForm = () => ({
  title: "",
  sn: "",
  price: "",
  description: "",
  source_file: "",
  juanshu: "",
  pujidi: "",
  shizu: "",
  shiqianzu: "",
  timing: "",
  xiuzhuanzhe: "",
  banbennian: "",
  tanghao: "",
  zibei: "",
  zhuanxieriqi: "",
  fayuandi: ""
});

export default {
  name: "MyPushuDetail",
  components: {
    "el-form": Form,
    "el-form-item": FormItem,
    "el-divider": Divider,
    "el-breadcrumb": Breadcrumb,
    "el-breadcrumb-item": BreadcrumbItem,
    detail: Detail
  },
  data() {
    return {
      detail: initForm(),
      fileList: [],
      uploadApi:
        process.env.NODE_ENV === "development"
          ? `/api/material/upload`
          : `${apiRoot}material/upload`
    };
  },
  activated() {
    this.detail = initForm();
    this.fetchDetail();
  },
  methods: {
    async fetchDetail() {
      const id = this.$route.params.id;
      // const id = 2;
      if (!id) return;
      const {
        data: { material_info }
      } = await materialApis.myMaterialDetail({
        id
      });
      this.detail = material_info;
      console.log(material_info);
    }
  }
};
</script>

//
<style lang="scss">
// .el-form-item {
//   display: flex;
//   flex-flow: column nowrap;
// }
//
.upload-form-item {
  .el-form-item__label {
    text-align: left;
    font-size: 12px;
    color: #333;

    &::after {
      content: "：";
    }
  }

  .el-form-item__content {
    display: inline-flex;
    align-items: center;
    height: 100%;
    width: 65%;

    span {
      color: #CA0A15;
      font-size: 12px;
      line-height: 1.2;
      word-break: break-all;
    }
  }
}
.upload-form {
  .el-divider--horizontal {
    margin: 0;
    background-color: #f0f0f0;
  }
}
</style>

<style lang="scss" scoped>
.upload-pushu {
  position: relative;
  padding: 20px;
  width: 100%;
  // height: 100%;
  // min-height: calc(100% - 50px);
  box-sizing: border-box;

  .content-inner {
    height: 100%;
    width: 100%;
    background: #f7f7f7;
    border-radius: 4px;
    // padding: 0 20px;
    box-sizing: border-box;
    overflow: auto;
    padding-bottom: 20px;
  }

  .pushu-header {
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;

    &::after {
      content: "";
      display: block;
      height: 1px;
      width: calc(100% - 40px);
      position: absolute;
      bottom: 0;
      left: 20px;
      background-color: #f0f0f0;
    }

    .title-text {
      font-size: 14px;
      color: #666666;
    }
  }

  .upload-form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    padding: 0 20px;
    margin: 20px;
    background-color: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 4px;

    .upload-form-item {
      flex: none;
      width: 24%;
      height: 80px;
      line-height: 80px;
      margin-bottom: 0px;
      // display: flex;
      // flex-flow: column nowrap;
    }
  }
}
</style>
